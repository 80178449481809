import React from 'react';
import { withTranslation } from 'react-i18next';

import {Hero, Footer, Pricing, Features, Feature, References, Reference, SignupDivider, BookDemo} from '../../components';
import {LandingLayout} from '../../layouts';

const Landing = ({t, i18n}) => {
  return (
    <LandingLayout
      title="Tarjoupohja - Käytä pohjia tarjouksiin, jotka lähetät asiakkaillesi. "
      language="fi"
      meta={[
        {name: 'description', content: 'Tarjouspohja ohjelma - Duuers on web-pohjainen työkalu tarjousten luomiseen ja lähettämiseen. Työkalun muokattaviin pohjiin voit lisätä oman yrityksesi brandin, tuotteet ja palvelut. Pohjien avulla tarjousten luominen kestää alle minuutin!'}, 
        {name:'robots', content:'noindex,nofollow'}
      ]}
    >
      <Hero>
        <h1 className="blue font_second light font102 top45">Tarjouspohjat pienille yrityksille</h1>
    		<div className="margin_auto top40 max_width770 blue light font22 text">
    			<p>Nopeampi ja paljon helpompi käyttää kuin Word dokumentti</p>
    		</div>
    		<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size50 transparent_blue border border_blue radius18 padding_sides35 sides25 top45">{t('menu.signup')}</a>
      </Hero>
      <Features title="Säästä aikaa. Säästä rahaa. Kasvata yritystäsi." subtitle="Me haluamme tehdä tarjousten luomisesta mahdollisimman helppoa.">
 			 	<Feature src="/images/features/proposal.png" name="Muokattavat tarjouspohjat yrityksellesi" description="Unohda perinteiset Word dokumentit. Duuersin avulla lisäät oman logosi, brändi värisi, kuvasi ja tuotteesi, jopa videot halutessasi! Voit luoda useita erilaisia pohjia eri tyyppisille palveluille."/>
        <Feature src="/images/features/messages.png" name="Keskustele asiakkaan kanssa ja muuta pohjia samaan aikaan" description="Duuersin sisäänrakennetun chat toiminnon avulla asiakkaat voivat antaa palautteensa tarjoukseen. Voit päivittää tarjousta samaan aikaan kun käyt keskustelua asiakkaan kanssa ja lähettää samalla päivitetyn tarjouksen hyväksyttäväksi."/> 
        <Feature src="/images/features/proposalarchive.png" name="Järjestä tarjouspohjat asiakkaan tai statuksen mukaisesti" description="Kaikki tarjouksesi on talletettu ja järjestyksessä, niiden statuksen (avoinna, hyväksytty, hylätty, jne), ja voit hakea ja järjestää tarjouksia asiakkaan nimen tai hakusanan mukaisesti."/> 
        <Feature src=" /images/features/mobile-friendly.png" name="Duuers mobiilissa (tulossa pian!)" description="Työsi on tien päällä, joten tarvitset työkaluja, jotka toimivat aina ja missä tahansa. Duuersin avulla voit tarkastella, kommentoida ja hyväksyä tarjouksia puhelimellasi."/>
      </Features>
      <SignupDivider/>
      <References>
        <Reference name="Reeta Laamo" company="Red & Blue" image="/images/references/reeta_laamo_500x500.png">
         Duuers nopeuttaa ja helpottaa tarjousten tekemistä. Meillä virheiden määrä on vähentynyt, ja vastaanottajat ovat kiitelleet siistejä tarjouspohjia. Yhteen tarjoukseen unohdin väärän hinnan, mutta pystyin Duuersin avulla muokkaamaan lähettämääni tarjousta jo ennen kuin vastaanottaja oli avannut tarjouksen. Duuers tarjoaa näkymän siihen miten tarjouksia tulevaisuudessa pyöritellään. Suosittelen!
        </Reference>
        <Reference  name="Iiro Hänninen" company="Korttilinna" image="/images/references/iiro_500x500.png">
  			  Duuers oli nopea ja helppo ottaa käyttöön. Laatu ja näkyvyys ovat parantuneet huomattavasti ja myös asiakkaat ovat antaneet pointsit palvelusta! Käytännössä olen siirtänyt koko tarjoustyöskentelyni Duuersiin. Takaisin vanhaan ei ole paluuta!
  			</Reference>
  			<Reference  name="Jani Teräväinen" company="Työmaapalvelut Express" image="/images/references/jani_500x500.png">
  			  Duuersin merkitys kasvaa koko ajan! Tarjoustyöskentely on nopeutunut ja tehostunut huomattavasti ja kannustanut uudistamaan muitakin yrityksen osa-alueita. Olen jälleen askeleen lähempänä paperitonta toimistoa!
  			</Reference>
  			<Reference  name="Heikki Holmström" company="Munkkiniemen Remonttiapu">
  			  Duuers nopeuttaa tarjousten tekoaikaani puolella ja nopeutuu koko ajan. Tarjousten tekemisestä on tullut helppoa, ja erityisesti uuden tarjouksen aloittamisesta on tullut mukavaa!
  			</Reference>
      </References>
      <Pricing/>
      <BookDemo/>
      <Footer/>
    </LandingLayout>
);
};


export default withTranslation()(Landing);
